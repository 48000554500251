@font-face {
  font-family: ProximaNovaRegular;
  src: url("./assets/Fonts/ProximaNova/ProximaNova-Regular.ttf");
}
@font-face {
  font-family: ProximaNovaBold;
  src: url("./assets/Fonts/ProximaNova/ProximaNova-Bold.ttf");
}
html {
  overflow-x: hidden;
}
body {
  background-color: #16161a;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  min-width: 331px;
  font-family: "ProximaNovaRegular";
}

.container {
  margin: 0 auto;
  max-width: 1110px;
  padding: 0 20px;
  /* background-color: #16161A; */
  color: #fff;
}
.road-map-container {
  margin: 0 auto;
  max-width: 900px;
  padding: 0 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.rewardcontainer {
  margin: 0 auto;
  max-width: 1446px;
  color: #fff;
}

.appbarcolor {
  background-color: aqua;
}

.nav-container {
  margin: auto;
  max-width: 1110px;
  color: #fff;
  background-color: #16161a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  padding: 0 20px;
}

.routerlink {
  text-decoration: none;
  color: #fff;
}

/* =========================================
Navbar hover effect
========================================= */

.dropdown-menu {
  display: none;
  z-index: 1;
}

.menu:hover .dropdown-menu {
  display: block;
}

.emissions {
  border-collapse: collapse;
  font-family: "ProximaNovaRegular";
}

.emissions th {
  font-size: small;
  color: #fafafa;
  text-align: center;
  padding: 10px;
}

.emissions td {
  font-size: x-small;
  color: #fafafa;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.roadmap-navbar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  transition: background-color 0.3s ease;
  width: 100%;
  overflow-x: auto;
  max-width: 900px;
}

.roadmap-navbar.sticky-nav {
  position: fixed;
  top: 15px;
  z-index: 1000;
  margin-top: 0px;
}

.roadmap-navbar a {
  cursor: pointer;
  border: thin solid #fafafa;
  margin-left: 10px;
  margin-right: 10px;
}

.roadmap-navbar.sticky-nav a {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.hide-wide {
  display: none;
}

.hide-narrow {
  display: block;
}

@media only screen and (max-width: 767px) {
  .emissions th {
    font-size: 6pt;
    padding: 5px;
  }

  .emissions td {
    font-size: 6pt;
  }
  .roadmap-navbar {
    max-width: 95vw !important;
  }

  .roadmap-navbar a {
    margin-left: 5px;
    margin-right: 5px;
  }

  .roadmap-navbar.sticky-nav {
    max-width: 95vw !important;
  }

  .road-map-container {
    padding: 0;
  }

  .hide-wide {
    display: block;
  }
  .hide-narrow {
    display: none;
  }
}

.green-hover:hover {
  color: #aeff00 !important;
}

.spacer-show {
  display: block !important;
}

.spacer {
  display: none;
  height: 45px;
}
